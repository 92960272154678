import { useEffect, useState } from "react";
import { teamsColl, toRankings, usersColl } from "./model";
import { onSnapshot } from "firebase/firestore";

export type Ranking = {
  name: string;
  allTimeScore: number;
  scoreLastWeek: number;
};

type SortedRankings = Ranking[];

export function useChinups(): [teams: SortedRankings, users: SortedRankings] {
  const [teams, setTeams] = useState<SortedRankings>([]);
  const [users, setUsers] = useState<SortedRankings>([]);

  useEffect(
    () =>
      onSnapshot(usersColl, (users) => {
        const scores = toRankings(users);
        setUsers(scores.sort((a, b) => b.scoreLastWeek - a.scoreLastWeek));
      }),
    []
  );
  useEffect(
    () =>
      onSnapshot(teamsColl, (teams) => {
        const scores = toRankings(teams);
        setTeams(scores.sort((a, b) => b.scoreLastWeek - a.scoreLastWeek));
      }),
    []
  );

  return [teams, users];
}
