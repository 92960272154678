import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  login_hint: "user@reaktor.com",
});

const auth = getAuth();

export function useLogin():
  | "logged-in"
  | "access-denied"
  | "signed-out"
  | "loading" {
  const [state, setState] = useState<
    "logged-in" | "access-denied" | "signed-out" | "loading"
  >("loading");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Don't wory - this is enforced at the Firebase level (see security rules)
        if (
          user.email?.endsWith("@reaktor.com") ||
          user.email?.endsWith("@reaktor.fi")
        ) {
          setState("logged-in");
        } else {
          setState("access-denied");
        }
      } else {
        setState("signed-out");
      }
    });

    return () => unsubscribe();
  }, []);

  return state;
}

export function login() {
  signInWithPopup(auth, provider);
}

export function logout() {
  auth.signOut();
}
