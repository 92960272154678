import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  // This is public information
  apiKey: "AIzaSyAcbi15BYu9tRuYvcOYIh2rTX4iycQRoiw",
  authDomain: "up-reaktor-com.firebaseapp.com",
  projectId: "up-reaktor-com",
  storageBucket: "up-reaktor-com.appspot.com",
  messagingSenderId: "650772939405",
  appId: "1:650772939405:web:c3e48553c148c8d96698c4",
} as const;

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
