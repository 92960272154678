import {
  increment,
  doc,
  setDoc,
  collection,
  QueryDocumentSnapshot,
  DocumentData,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "./firebase";
import { Ranking } from "./use-chinups";
import { isProd } from "./env";

const WEEK = 1000 * 60 * 60 * 24 * 7;

const sport = isProd() ? "chinups" : "testing";

export const usersColl = collection(db, "sports", sport, "users");
export const teamsColl = collection(db, "sports", sport, "teams");

export const incrementTodaysChinups = (
  user: string,
  team: string,
  numChinups: number = 1
) => {
  const todayDateISO = new Date().toISOString().split("T")[0];

  const data = {
    [todayDateISO]: increment(numChinups),
  };
  void setDoc(doc(usersColl, user.toLocaleLowerCase()), data, { merge: true });
  void setDoc(doc(teamsColl, team.toLocaleLowerCase()), data, { merge: true });
};

export function toRankings(
  users: QuerySnapshot<DocumentData, DocumentData>
): Ranking[] {
  return users.docs.map((doc) => ({
    name: doc.id,
    allTimeScore: Object.values(doc.data()).reduce(
      (acc, score) => acc + score,
      0
    ),
    scoreLastWeek: Object.entries(doc.data())
      .filter(([date]) => Date.now() - new Date(date).getTime() < WEEK)
      .reduce((acc, [_date, score]) => acc + score, 0),
  }));
}

export const recordToRanking = (
  user: QueryDocumentSnapshot<DocumentData, DocumentData>
): Ranking => ({
  name: user.id,
  allTimeScore: Object.values(user.data()).reduce(
    (acc, score) => acc + score,
    0
  ),
  scoreLastWeek: Object.entries(user.data())
    .filter(([date]) => Date.now() - new Date(date).getTime() < WEEK)
    .reduce((acc, [_date, score]) => acc + score, 0),
});
