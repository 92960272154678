import { useEffect, useState } from "react";

export function useRotatingValue<const T extends any[]>(
  intervalMs: number,
  values: T
): T[number] {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % values.length);
    }, intervalMs);
    return () => clearInterval(interval);
  }, [intervalMs, values.length]);
  return values[index];
}
