import { PropsWithChildren } from "react";
import { login, logout, useLogin } from "./use-login";

export function LoginWrapper({ children }: PropsWithChildren) {
  const loggedIn = useLogin();

  if (loggedIn === "loading") {
    return <div>Loading...</div>;
  }

  if (loggedIn === "signed-out") {
    return (
      <div>
        <h1>Signed out</h1>
        <button id="leuka" onClick={login}>
          Sign in to do chin ups!
        </button>
      </div>
    );
  }
  if (loggedIn === "access-denied") {
    return (
      <div>
        <h1>Access denied</h1>
        <a href="/" onClick={logout} className="logout">
          Log out
        </a>
      </div>
    );
  }

  return children;
}
