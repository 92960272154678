import { MouseEventHandler, useCallback, useState } from "react";
import { incrementTodaysChinups } from "./model";
import { useChinups } from "./use-chinups";
import { usePreservedState } from "./use-preserved-state";
import { useRotatingValue } from "./use-rotating-value";
import { logout } from "./use-login";
import { IncrementButton } from "./IncrementButton";

function App() {
  const [username, setUsername] = usePreservedState("username", "");
  const [team, setTeam] = usePreservedState("team", "");
  const addChinup = useCallback(
    (numChinups: number) => {
      incrementTodaysChinups(username, team, numChinups);
    },
    [team, username]
  );
  const [page, setPage] = useState<"teams" | "users">("teams");
  const [teams, users] = useChinups();
  const rankings = page === "teams" ? teams : users;
  const total = rankings.reduce((acc, rank) => acc + rank.allTimeScore, 0);
  const totalLastWeek = rankings.reduce(
    (acc, rank) => acc + rank.scoreLastWeek,
    0
  );
  const showChangeClass = useRotatingValue(3000, ["", "show-muutos"]);
  const togglePage: MouseEventHandler<HTMLAnchorElement> = useCallback((e) => {
    e.preventDefault();
    setPage((curr) => (curr === "teams" ? "users" : "teams"));
  }, []);

  return (
    <div className={showChangeClass}>
      <h1>CHIN UPS</h1>
      <div id="veto">
        <label>
          Team
          <input
            name="team"
            id="team"
            placeholder="CHINUP TEAM NAME"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
          />
        </label>
        <label>
          Puller
          <input
            name="vetaja"
            id="vetaja"
            placeholder="PULLER NAME"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>

        <IncrementButton onClick={addChinup} disabled={!username || !team} />
      </div>
      <div>
        <a href="/" id="toggle" className="center-link" onClick={togglePage}>
          Show {page === "teams" ? "users" : "teams"}
        </a>
        <div className="total">
          <div className="name">TOTAL</div>
          <div className="leukoja score-toggle">All time: {total}</div>
          <div className="muutos score-toggle active">
            This week: +{totalLastWeek}
          </div>
        </div>
        {rankings.map((rank) => (
          <div className="team" key={rank.name}>
            <div className="name">{rank.name.toUpperCase()}</div>
            <div className="leukoja score-toggle">{rank.allTimeScore}</div>
            <div className="muutos score-toggle active">
              +{rank.scoreLastWeek}
            </div>
          </div>
        ))}
      </div>
      <a href="/" onClick={logout} className="logout">
        Log out
      </a>
    </div>
  );
}

export default App;
