import { useCallback, useEffect, useState } from "react";

export function IncrementButton({
  onClick,
  disabled,
}: {
  onClick: (numIncremented: number) => void;
  disabled: boolean;
}) {
  const [count, setCount] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  useEffect(() => {
    setFadeOut(false);
    if (count === 0) {
      return;
    }

    const fadeoutTimeout = setTimeout(() => {
      setFadeOut(true);
    }, 2000);
    const endTimeout = setTimeout(() => {
      setCount(0);
    }, 3000);

    return () => {
      clearTimeout(fadeoutTimeout);
      clearTimeout(endTimeout);
    };
  }, [count]);

  const handleClick = useCallback(() => {
    setCount((c) => c + 1);
    onClick(1);
  }, [onClick]);

  return (
    <>
      <button onClick={handleClick} disabled={disabled} id="leuka">
        Chin up!
      </button>
      <div id="iso" className={fadeOut ? "pow" : undefined}>
        {count ? count : ""}
      </div>
    </>
  );
}
